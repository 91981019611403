<template>
	<el-form
		:model="formData"
		:rules="rules"
		:size="size"
		ref="queryForm"
		:inline="inline"
		:class="inline ? 'publi-form publi-flex' : 'demo-ruleForm'"
		:label-position="inline ? 'left' : labelPosition"
	>
		<div v-for="column in columns" :key="column.prop" :class="column.className">
			<el-form-item :label="column.label" :prop="column.prop" v-if="column.type == 'input'">
				<!-- 普通输入框 -->
				<el-input
					v-model.trim="formData[column.prop]"
					:clearable="column.clearable || true"
					:disabled="column.disabled"
					:placeholder="column.placeholder || inline ? '请输入' : '请输入' + column.label"
					:maxlength="column.maxlength"
					:type="column.inputType || 'text'"
				>
					<template v-if="column.slot" slot="append">{{ column.slot }}</template>
				</el-input>
			</el-form-item>
			<el-form-item :label="column.label" class="attractionAddress" :prop="column.prop" v-if="column.type == 'attractionAddress'">
				<!-- 详细地址输入框 -->
				<el-input
					v-model.trim="formData[column.prop]"
					:clearable="column.clearable || true"
					:disabled="column.disabled"
					:placeholder="column.placeholder || inline ? '请输入' : '请输入' + column.label"
					:maxlength="column.maxlength"
					:style="{ width: isShowDetail ? '100%' : 'calc(100% - 74px)' }"
				></el-input>
				<el-button type="text" @click="chooseAddress" v-if="!isShowDetail">选择地址</el-button>
			</el-form-item>
			<el-form-item :label="column.label" :prop="column.prop" v-if="column.type == 'autocomplete'">
				<!-- 模糊查询输入框 -->
				<el-autocomplete
					v-model.trim="formData[column.prop]"
					:fetch-suggestions="
						(queryString, callback) => {
							querySearchAsync(queryString, callback, column.options);
						}
					"
					:clearable="column.clearable || true"
					:placeholder="column.placeholder || inline ? '请输入' : '请输入' + column.label"
					:disabled="column.disabled"
					@select="
						(val) => {
							handleSelect(val, column.prop);
						}
					"
				></el-autocomplete>
			</el-form-item>
			<el-form-item :label="column.label" :prop="column.prop" v-if="column.type == 'select'">
				<el-select
					v-model="formData[column.prop]"
					:placeholder="column.placeholder || inline ? '请选择' : '请选择' + column.label"
					:clearable="column.clearable || true"
					:filterable="column.filterable || false"
					:multiple="column.multiple || false"
					:disabled="column.disabled"
				>
					<el-option
						v-for="(options, index) in column.options"
						:label="column.optionsDefault ? options[column.optionsDefault[0]] : options"
						:value="column.optionsDefault ? options[column.optionsDefault[1]] : options"
						:key="column.optionsDefault ? options[column.optionsDefault[1]] : index"
					></el-option>
				</el-select>
			</el-form-item>
			<el-form-item :label="column.label" :prop="column.prop" v-if="column.type == 'cascader'">
				<el-cascader
					v-model="formData[column.prop]"
					:placeholder="column.placeholder || inline ? '请选择' : '请选择' + column.label"
					:clearable="column.clearable || true"
					:disabled="column.disabled"
					:options="column.options"
				></el-cascader>
			</el-form-item>
			<el-form-item :label="column.label" :prop="column.prop" v-if="column.type == 'datetimerange'">
				<el-date-picker
					v-model="formData[column.prop]"
					:format="column.format || 'yyyy-MM-dd HH:mm:ss'"
					:value-format="column.valueFormat || 'yyyy-MM-dd HH:mm:ss'"
					:type="column.rangeType || 'datetimerange'"
					:range-separator="column.range || '至'"
					:unlink-panels="column.unlinkPanels || false"
					:start-placeholder="column.rangePlaceholder ? column.rangePlaceholder[0] : '开始时间'"
					:placeholder="column.placeholder"
					:end-placeholder="column.rangePlaceholder ? column.rangePlaceholder[1] : '结束时间'"
					:clearable="column.clearable || true"
					:disabled="column.disabled"
				></el-date-picker>
			</el-form-item>
			<el-form-item :label="column.label" :prop="column.prop" v-if="column.type == 'textarea'">
				<el-input
					v-model.trim="formData[column.prop]"
					type="textarea"
					:maxlength="column.maxlength"
					:show-word-limit="column.showWordLimit || true"
					:disabled="column.disabled"
					:placeholder="column.placeholder || inline ? '请输入' : '请输入' + column.label"
					:resize="column.resize || 'none'"
				></el-input>
			</el-form-item>
			<el-form-item v-if="column.type == 'addressType'" class="addressClass clearfix" label="地址" :required="column.required || false">
				<el-form-item v-for="(item, index) in column.list" :key="index" :class="item.className">
					<el-input
						v-model.trim="item.default"
						:clearable="item.clearable || true"
						:disabled="item.disabled"
						:placeholder="item.placeholder || inline ? '请输入' : '请输入' + item.label"
						:maxlength="item.maxlength"
						:type="item.inputType || 'text'"
					></el-input>
				</el-form-item>
			</el-form-item>
			<el-form-item :label="column.label" :prop="column.prop" v-if="column.type == 'slot' && column.show == true">
				<slot :name="column.prop"></slot>
			</el-form-item>
			<el-form-item v-if="column.type == 'uploadImg'" :label="column.label" :prop="column.prop">
				<Upload
					v-model="formData[column.prop]"
					:modulePath="column.modulePath"
					@uploadImg="uploadImg"
					:operator="column.operator"
					:maxCount="column.maxCount"
					:disabled="isShowDetail"
				></Upload>
			</el-form-item>
		</div>
		<el-form-item v-if="hasBtn" class="func-btn" :style="{ marginBottom: !inline ? '10px !important' : '0', marginTop: !inline ? '4px' : '0' }">
			<!-- 查询按钮 -->
			<template v-if="searchBtn">
				<el-button type="primary" @click="queryByWord()">搜索</el-button>
			</template>
			<!-- 保 存 -->
			<el-button :loading="saveLoading" type="primary" @click="queryByWord" v-if="saveBtn">保 存</el-button>
			<!-- 重置按钮 -->
			<el-button @click="onReset" v-if="resetBtn">取 消</el-button>
			<!-- 其他功能按钮插槽 -->
			<slot name="btnSlot" :form="formData"></slot>
		</el-form-item>
	</el-form>
</template>
<script>
export default {
	props: {
		columns: {
			//表单配置项
			type: Array,
			default: () => [],
		},
		rules: {
			//验证规则
			type: Object,
			default: () => {
				return {};
			},
		},
		size: {
			//表单内组件尺寸
			type: String,
			default: '',
		},
		resetBtn: {
			//显示重置按钮
			type: Boolean,
			default: false,
		},
		saveBtn: {
			//显示保存按钮
			type: Boolean,
			default: false,
		},
		labelwidth: {
			//label宽度
			type: String,
			default: '120px',
		},
		inline: {
			//是否为行内式表单
			type: Boolean,
			default: true,
		},
		searchBtn: {
			//显示搜索按钮
			type: Boolean,
			default: true,
		},
		labelPosition: {
			//表单域标签的位置
			type: String,
			default: 'top',
		},
		saveLoading: {
			//保存按钮loading
			type: Boolean,
			default: false,
		},
		districtList: {
			//区县列表
			type: Array,
			default: () => [],
		},
		marketList: {
			//市列表
			type: Array,
			default: () => [],
		},
		economizeList: {
			//省列表
			type: Array,
			default: () => [],
		},
		isShowDetail: {
			//是否显示详情
			type: Boolean,
			default: false,
		},
		images: {
			//图片列表
			type: Array,
			default: () => [],
		},
		hasBtn: {
			//是否显示按钮
			type: Boolean,
			default: true,
		},
	},
	components: {
		Upload: () => import('@/components/Upload/index.vue'),
	},
	data() {
		return {
			formData: {},
		};
	},
	methods: {
		// 模糊查询选择
		handleSelect(subInput, subProp) {
			this.formData[subProp] = subInput.name;
		},
		// // 省市联动
		// changeEconomize(val, type) {
		// 	// type 1省 2市 3区县
		// 	switch (type) {
		// 		case 1:
		// 			this.$set(this.formData, 'provinceDictionaryItem', val);
		// 			this.$set(this.formData, 'cityDictionaryItem', '');
		// 			this.$set(this.formData, 'regionCode', '');
		// 			this.$emit('changeEconomize', { type: 1, val });
		// 			this.$forceUpdate();
		// 			break;
		// 		case 2:
		// 			this.$set(this.formData, 'cityDictionaryItem', val);
		// 			this.$set(this.formData, 'regionCode', '');
		// 			this.$emit('changeEconomize', { type: 2, val });
		// 			this.$forceUpdate();
		// 			break;
		// 		case 3:
		// 			this.$set(this.formData, 'regionCode', val);
		// 			this.$forceUpdate();
		// 			break;
		// 		default:
		// 			break;
		// 	}
		// },
		// 搜索建议
		querySearchAsync(queryString, cb, options) {
			queryString ? this.$emit('getList') : '';
			clearTimeout(this.timeout);
			this.timeout = setTimeout(() => {
				cb(options);
			}, 1000 * Math.random());
		},
		// 查询
		queryByWord() {
			this.$refs['queryForm'].validate((valid) => {
				if (valid) {
					this.$emit('query', this.formData);
				}
			});
		},
		// 重置
		onReset() {
			this.$refs['queryForm'].resetFields();
			this.$emit('reset');
		},
		// 处理表单数据
		handleProp(columns) {
			this.formData = {};
			for (let i = 0; i < columns.length; i++) {
				const element = columns[i];
				// 去除插槽
				if (element.type != 'slot') {
					// this.formData初始值不能设置为null,新增属性会报错
					this.$set(this.formData, element.prop, element.default);
				}
			}
		},
		// 选择地址
		chooseAddress() {
			this.$emit('chooseAddress', this.formData);
		},
		uploadImg(dataImg) {
			this.formData.images = dataImg.map((item) => {
				return {
					url: item.url,
					uid: item.uid,
					title: item.title,
				};
			});
		},
	},
	watch: {
		columns: {
			handler(newVal) {
				this.handleProp(newVal);
			},
			immediate: true,
			deep: true,
		},
	},
};
</script>
<style lang="scss" scoped>
.addressClass {
	&-content {
		width: calc((100% - 32px) / 3);
		margin-right: 16px;
		margin-bottom: 0;
	}
}
.attractionAddress {
	/deep/ .el-button {
		width: 56px;
		border-bottom: 1px solid #1db9b1;
		margin-left: 16px;
		padding: 0;
		&:hover {
			color: #33a7a1;
			border-bottom: 1px solid #33a7a1;
		}
	}
}

.el-form-item__label-wrap {
	margin-left: 10px;
}

.publi-flex {
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	align-content: center;
	align-items: center;
	.el-form-item {
		margin-bottom: 16px !important;
	}
	/deep/ .el-select {
		width: 120px !important;
		.el-input {
			width: 120px !important;
			.el-input__inner {
				width: 120px !important;
			}
		}
	}
	/deep/ .el-input {
		width: 160px;
	}
}
.func-btn /deep/ .el-form-item__content {
	display: flex;
	justify-content: center;
}

/deep/ .mr0 {
	margin-right: 0 !important;
}
/deep/ .w50 {
	width: calc(50% - 8px);
	display: inline-block;
}
/deep/ .w40 {
	width: calc(50% - 14px);
	display: inline-block;
}
/deep/ .m28 {
	margin-right: 28px;
}
</style>


